import React from 'react';
import "./Button.css";

interface ButtonProps {
    title: string;
}

const Button: React.FC<ButtonProps> = (props) => {
    return (
        <div className="Button">
            <div>
                {props.title}
            </div>
        </div>
    )
}

export default Button;
