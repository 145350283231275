import React from 'react';
import Bifurcation from './Bifurcation';
import Nav from "./Nav";
import pages from "./Pages";
import './App.css';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});

history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const App: React.FC = () => {
    return (
        <div className="App">
            <Router history={history}>
                <Nav pages={pages} />
                <div className="name">
                    <div className="bigName">
                        Ben
                        </div>
                    <div className="bigName">
                        Weitzman
                    </div>
                </div>

                <Bifurcation />
            </Router >
        </div >
    );
}

export default App;
