import { PageData } from "./Nav";
import React from "react";
import ReactGA from "react-ga";

interface ProjectProps {
    name: string;
    description?: string;
    url: string;
}

const Project: React.SFC<{ project: ProjectProps }> = ({ project }) => {
    return (
        <div className="project-wrapper">
            <div className="project">
                <span className="project-title">
                    <ReactGA.OutboundLink to={project.url}
                        eventLabel={`project:${project.name}`}>
                        {project.name}
                    </ReactGA.OutboundLink>
                </span>
                {project.description == null ? <React.Fragment /> :
                    <div className="project-description">
                        {project.description}
                    </div>
                }

            </div>
            <div className="project-border"></div>
        </div>
    );
}

const projects: ProjectProps[] = [
    {
        name: "Co – Star",
        url: "https://www.costarastrology.com"
    },
    {
        url: "https://gitlab.com/costar-astrology/freer-simple-contrib",
        name: "freer-simple-contrib",
        description: "Useful tools for working with free monads"
    },
    {
        url: "https://gitlab.com/benweitzman/quicktable/",
        name: "quicktable",
        description: "Declarative, type safe list views for iOS"
    },
    {
        url: "https://gitlab.com/benweitzman/autolist",
        name: "autolist",
        description: "List animations in Flutter, automatically"
    }
]

export const pages: PageData[] = [
    {
        url: "/about",
        title: "About",
        component: () =>
            <span>
                <span role="img" aria-label="wave">👋</span>
                <p>
                    Hi I'm Ben!
                </p>
                <p>
                    I'm a software developer living in NYC.
                </p>
                <p>
                    I like using strongly typed functional languages to specify, test, and analyze programs.
                </p>
            </span>
    },
    {
        url: "/projects",
        title: "Projects",
        component: () =>
            <div className="projects">
                {projects.map((p) => <Project project={p} />)}
            </div>
    },
    {
        url: "/blog-posts",
        title: "Blog posts",
        component: () =>
            <div>
                5.16.19 <a href="https://medium.com/co-star-engineering/deprecating-api-endpoints-with-servant-1c00f8a211ca">
                    Deprecating API endpoints with Servant
                </a>
                <br />
                4.17.19 <a href="https://medium.com/co-star-engineering/continuous-improvement-with-hlint-code-smells-e490886558a1">
                    Continuous improvement with hlint code smells
                </a>
            </div>
    }
]

export default pages;
