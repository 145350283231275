import { instantiateStreaming } from "@assemblyscript/loader";

export interface API {
  [index: string]: unknown;
  PixelArray_ID: number;
  drawDiagram(arrayPtr: number, width: number, height: number, mouseX: number, mouseY: number, power: number): void;
}

const imports: any = {};

export default instantiateStreaming<API>(fetch("./wasm/as-api.wasm"), imports);