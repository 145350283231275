import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import './Bifurcation.css';
import API, {API as APIT} from "./as-api";
import {ASUtil} from "@assemblyscript/loader";

const Bifurcation = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);
    const [power, setPower] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const resizeHandler = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener("resize", resizeHandler);

        return () => {
            window.removeEventListener("resize", resizeHandler);
        }
    }, []);

    const onMouseMove = useCallback(({ clientX, clientY, movementX, movementY }: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        setMouseX(clientX);
        setMouseY(clientY);
        setPower((oldPower) => Math.min(100000, oldPower + movementX * movementX + movementY * movementY));
    }, []);


    const onTouchStart = useCallback((e: React.TouchEvent<HTMLCanvasElement>) => {
        const movementX = e.touches[0].clientX - mouseX;
        const movementY = e.touches[0].clientY - mouseY;
        setMouseX(e.touches[0].clientX);
        setMouseY(e.touches[0].clientY);
        setPower(Math.min(100000, power + movementX * movementX + movementY * movementY));

        e.preventDefault();
    }, [power, mouseX, mouseY]);

    const [wasm, setWasm] = useState<APIT & ASUtil | undefined>(undefined);
    useEffect(() => {
        API.then((api) => {
            setWasm(api.exports);
        })
    }, []);

    const [imageDataPtr, setImageDataPtr] = useState<number | undefined>(undefined);
    const [imageData, setImageData] = useState<ImageData | undefined>(undefined);

    const ctx = useMemo(() => {
        if (!canvasRef.current) {
            return null;
        }
        return canvasRef.current.getContext("2d");
    // eslint-disable-next-line
    }, undefined);

    useLayoutEffect(() => {
        canvasRef.current!.height = height;
        canvasRef.current!.width = width;
    }, [width, height]);

    useEffect(() => {
        if (!wasm) {
            return;
        }

        const size = width * height * 4;
        const newImageDataPtr = wasm.__pin(wasm.__newArray(wasm.PixelArray_ID, size));
        setImageDataPtr((oldImageDataPtr) => {
            if (oldImageDataPtr) {
                wasm.__unpin(oldImageDataPtr);
            }
            return newImageDataPtr
        });
        const view = wasm.__getUint8ClampedArrayView(newImageDataPtr);
        let newImageData = new ImageData(view, width, height);
        setImageData(newImageData);
    }, [wasm, height, width]);

    useLayoutEffect(() => {
        const renderFrame = () => {
            if (imageData && wasm) {

                imageData.data.fill(0);

                wasm.drawDiagram(imageDataPtr ?? 0, width, height, mouseX, mouseY, power);

                ctx!.putImageData(imageData, 0, 0);

                if (power > 1001) {
                    setPower(power - (power - 1000) / 3);
                }
            }
        }

        const frame = requestAnimationFrame(renderFrame);

        return () => {
            cancelAnimationFrame(frame);
        }
    }, [wasm, imageData, imageDataPtr, width, height, mouseX, mouseY, power, ctx]);

    return (
      <div className="Bifurcation">
          <canvas ref={canvasRef}
                  onMouseMove={onMouseMove}
                  onTouchMove={onTouchStart}
                  onTouchStart={onTouchStart}
          />
      </div>
    );
};

export default Bifurcation;