import React from 'react';
import { Route, Switch, Link, RouteComponentProps } from 'react-router-dom';
import Button from "./Button";

export interface PageData {
    url: string;
    title: string;
    component: React.ComponentType
}

export interface NavData {
    pages: PageData[];
}

const NavLinks: React.FC<NavData> = (props) => {
    return (
        <Route render={(route: RouteComponentProps<{}>) => {
            let extraClass = route.location.pathname === "/" ? "home" : "";
            return (
                <div className={`buttonGroup ${extraClass}`}>
                    {props.pages.map((page, idx) => {
                        return (
                            <Link to={page.url} key={idx}>
                                <Button title={page.title} />
                            </Link>
                        );
                    })}
                </div>
            );
        }} />
    );
}

const Panel: React.FC<NavData> = (props) => {
    return (
        <Switch>
            <Route exact path="/" />
            <Route>
                <div className="panel">
                    <div className="closeButton">
                        <Link to="/">
                            X
                        </Link>
                    </div>
                    <Switch>
                        {props.pages.map((page, idx) => {
                            const PageComponent: React.ComponentType = page.component;
                            return (
                                <Route exact path={page.url} key={idx}>
                                    <div className="panel-title">
                                        {page.title}
                                    </div>
                                    <PageComponent />
                                </Route>
                            );
                        })}
                    </Switch>
                </div>
            </Route>
        </Switch>
    );
}

const Nav: React.FC<NavData> = (props) => {
    return (
        <div className="nav">
            <NavLinks {...props} />
            <Panel {...props} />
        </div>
    );
}

export default Nav;
